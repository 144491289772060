// extracted by mini-css-extract-plugin
var _1 = "VideoSteps-module_button__P2T57";
var _2 = "VideoSteps-module_container__A13TL";
var _3 = "VideoSteps-module_desktop__q1AnY";
var _4 = "VideoSteps-module_divider__OECE0";
var _5 = "VideoSteps-module_header__sSelt";
var _6 = "VideoSteps-module_number__Dv_Ga";
var _7 = "VideoSteps-module_number1__GEIGj";
var _8 = "VideoSteps-module_number2__xLSex";
var _9 = "VideoSteps-module_number3__hnMOu";
var _a = "VideoSteps-module_steps__rGbdj";
var _b = "VideoSteps-module_stepsContainer__wEupz";
var _c = "VideoSteps-module_title__r4nZH";
export { _1 as "button", _2 as "container", _3 as "desktop", _4 as "divider", _5 as "header", _6 as "number", _7 as "number1", _8 as "number2", _9 as "number3", _a as "steps", _b as "stepsContainer", _c as "title" }
export default { "button": _1, "container": _2, "desktop": _3, "divider": _4, "header": _5, "number": _6, "number1": _7, "number2": _8, "number3": _9, "steps": _a, "stepsContainer": _b, "title": _c }
