/**
 * Enum for aspect_ratio
 * @name AspectRatio
 * @readonly
 * @enum {{ SQUARE: string, VERTICAL: string, LANDSCAPE: string }}
 */

export default {
  LANDSCAPE : 'landscape',
  SQUARE    : 'square',
  VERTICAL  : 'vertical'
};
