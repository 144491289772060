import React, { useState } from 'react';
import PropTypes from 'prop-types';
// TODO REB-95 Clean up components in web2
import {
  BodySmall as BodySmallImport,
  ControlButton as ControlButtonImport,
  Link as LinkImport,
  HeaderMedium as HeaderMediumImport,
  iconSizes,
  ShareIcon as ShareIconImport,
  Toolbar as ToolbarImport
} from '@animoto/components';
import evaluateFeature from '@animoto/ld-utils/evaluateFeature';

import {
  buttonWrapper,
  details,
  divider,
  link,
  videoTitle,
  wrapper
} from './VideoDetails.module.css';

export default function VideoDetails({
  description,
  displayDate,
  isMobile,
  isSharingEnabled,
  onOpenShareModal,
  producerName,
  title
}) {
  const isRebrand = evaluateFeature('rebrand', false);
  const BodySmall = BodySmallImport(isRebrand);
  const ControlButton = ControlButtonImport(isRebrand);
  const HeaderMedium = HeaderMediumImport(isRebrand);
  const Link = LinkImport(isRebrand);
  const ShareIcon = ShareIconImport(isRebrand);
  const Toolbar = ToolbarImport(isRebrand);

  const maxLength = isMobile ? 80 : 160;
  const showFullDescription = description.length <= maxLength;
  const [isTruncated, setIsTruncated] = useState(!showFullDescription);
  const linkText = isTruncated ? 'Show more' : 'Show less';

  let shownDescription;

  if (!isTruncated) {
    shownDescription = description;
  } else {
    const trimmedStr = !isTruncated ? description : description.slice(0, maxLength + 1);
    shownDescription = trimmedStr.substr(0, Math.min(trimmedStr.length, trimmedStr.lastIndexOf(' ')));
  }

  const onHandleClick = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div className={wrapper} id="details">
      <div>
        <HeaderMedium className={videoTitle}>{title}</HeaderMedium>
        <div className={details}>
          {producerName && (
            <BodySmall className={divider} color="secondary">{producerName}</BodySmall>
          )}
          {displayDate && (
            <BodySmall color="secondary">{displayDate}</BodySmall>
          )}
        </div>
        {description && (
          <>
            <BodySmall>{shownDescription}</BodySmall>
            {!showFullDescription && (
              <Link className={link} href="#details" onClick={onHandleClick} size="small">{linkText}</Link>
            )}
          </>
        )}
      </div>
      <div className={buttonWrapper}>
        <Toolbar isSmall>
          <ControlButton
            className="s-sharing-button"
            inToolbar
            isDisabled={!isSharingEnabled}
            onClick={onOpenShareModal}
          >
            <ShareIcon size={iconSizes.SMALL} />
            Share
          </ControlButton>
        </Toolbar>
      </div>
    </div>
  );
}

VideoDetails.propTypes = {
  description      : PropTypes.string,
  displayDate      : PropTypes.string,
  isMobile         : PropTypes.bool.isRequired,
  isSharingEnabled : PropTypes.bool.isRequired,
  onOpenShareModal : PropTypes.func.isRequired,
  producerName     : PropTypes.string,
  title            : PropTypes.string.isRequired
};
VideoDetails.defaultProps = {
  displayDate  : '',
  description  : '',
  producerName : ''
};
