import { trackUnstructDataEvent } from '@animoto/utils/analytics';
/**
 * Tracks the sharing of Animoto Videos. Original function may be seen Analytics.js
 *
 * @method trackShare
 *
 * @param {String} action - action to track
 * @param {String} action - target to track
 * @param {String} action - resolution to track if no resolution, pass 0
 * @param {String} action - details to track (if no details, pass the pageName)
 *
 * * */

export default function trackShare(action, target, resolution, details) {
  trackUnstructDataEvent('creationAppShare', {
    action,
    target,
    resolution : parseInt(resolution, 10) === 0 ? 'unavailable' : resolution,
    details
  });
}
