import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// TODO REB-95 Clean up components in web2
import {
  Button as ButtonImport,
  BodySmall as BodySmallImport,
  buttonSizes,
  buttonTypes,
  HeaderExtraSmall as HeaderExtraSmallImport,
  surfaceColors
} from '@animoto/components';
import { trackFeatureUsage } from '@animoto/utils/analytics';
import evaluateFeature from '@animoto/ld-utils/evaluateFeature';

import {
  container,
  desktop,
  text,
  textWrapper
} from './Banner.module.css';

// TODO: rename to Banner if test is successful
export default function Banner({
  hasVideoCompleted,
  isMobile,
  onClick
}) {
  const isRebrand = evaluateFeature('rebrand', false);
  const Button = ButtonImport(isRebrand);
  const BodySmall = BodySmallImport(isRebrand);
  const HeaderExtraSmall = HeaderExtraSmallImport(isRebrand);

  const wrapperClasses = classNames(container, { [desktop] : !isMobile });
  const mobileText = 'Make your own video in minutes';
  const mobileButtonText = 'Start free';
  const trackClick = () => {
    trackFeatureUsage({
      scope   : 'visitor-play-page',
      feature : 'banner',
      action  : 'click'
    });
  };

  const onClickButton = () => {
    trackClick();
    onClick();
  };
  return (
    <div className={wrapperClasses}>
      {isMobile ? (
        <>
          <div className={textWrapper}>
            <HeaderExtraSmall isInverted>Animoto</HeaderExtraSmall>
            <BodySmall isInverted>{mobileText}</BodySmall>
          </div>
          <Button
            onClick={onClickButton}
            size={buttonSizes.SMALL}
            surfaceColor={surfaceColors.ACCENT}
            type={hasVideoCompleted ? buttonTypes.PRIMARY : buttonTypes.SECONDARY}
          >
            {mobileButtonText}
          </Button>
        </>
      ) : (
        <>
          <HeaderExtraSmall className={text} isInverted>Create a video in minutes with Animoto.<br /> No credit card required. </HeaderExtraSmall>
          <Button
            onClick={onClickButton}
            size={buttonSizes.SMALL}
            surfaceColor={surfaceColors.ACCENT}
            type={hasVideoCompleted ? buttonTypes.PRIMARY : buttonTypes.SECONDARY}
          >
            Get started free
          </Button>
        </>
      )}
    </div>
  );
}

Banner.propTypes = {
  hasVideoCompleted : PropTypes.bool.isRequired,
  isMobile          : PropTypes.bool.isRequired,
  onClick           : PropTypes.func.isRequired
};
