let storedUserAttributes;

export function setUserAttributes(attributes) {
  storedUserAttributes = attributes;
}

export function userCan(key) {
  return storedUserAttributes.entitlements && storedUserAttributes.entitlements.indexOf(key) !== -1;
}

export function isRegisteredUser() {
  return !storedUserAttributes.anonymous;
}

export function getUserAttribute(key) {
  return storedUserAttributes[key];
}
