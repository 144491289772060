import PropTypes from 'prop-types';
// TODO REB-95 Clean up components in web2
import {
  BrandColorFacebookIcon as BrandColorFacebookIconImport,
  BrandColorTwitterIcon as BrandColorTwitterIconImport,
  IconThumbnailButton as IconThumbnailButtonImport,
  Modal as ModalImport,
  ModalHeader as ModalHeaderImport,
  modalSizes
} from '@animoto/components';
import React, { useEffect } from 'react';
import { trackFeatureUsage, trackEvent } from '@animoto/utils/analytics';
import evaluateFeature from '@animoto/ld-utils/evaluateFeature';
import CopyLink from '../CopyLink';
import facebookTimelineShare from '../../../../utils/facebookTimelineShare';
import trackShare from '../../../../utils/trackShare';

import { container } from './ShareModal.module.css';

export default function ShareModal({
  isOpen,
  onCloseModal,
  producerName,
  resolution,
  shareUrl,
  showSocialNetworks,
  videoTitle
}) {
  const isRebrand = evaluateFeature('rebrand', false);
  const BrandColorFacebookIcon = BrandColorFacebookIconImport(isRebrand);
  const BrandColorTwitterIcon = BrandColorTwitterIconImport(isRebrand);
  const IconThumbnailButton = IconThumbnailButtonImport(isRebrand);
  const Modal = ModalImport(isRebrand);
  const ModalHeader = ModalHeaderImport(isRebrand);

  const pageName = 'visitor-play-page';

  useEffect(() => {
    trackFeatureUsage({
      scope   : pageName,
      feature : 'share-modal',
      action  : isOpen ? 'open' : 'close'
    });
  }, [isOpen]);

  function onShareModalClose() {
    onCloseModal();
  }

  function onFacebookClick() {
    trackEvent(pageName, 'share-facebook');
    trackShare('share', 'facebook', resolution, `facebook-profile-button_clicked:${pageName}`);

    facebookTimelineShare(shareUrl);
  }

  function onTwitterClick() {
    trackEvent(pageName, 'share-twitter');
    trackShare('share', 'twitter', resolution, `twitter-button_clicked:${pageName}`);

    const width = 550;
    const height = 640;
    const left = window.top.outerWidth / 2 + window.top.screenX - (width / 2);
    const top = window.top.outerHeight / 2 + window.top.screenY - (height / 2);
    const shareText = `Watch this video ${producerName || 'that was'} made on animoto.com: "${videoTitle}"\n\n`;

    window.open(
      `http://twitter.com/intent/tweet?url=${encodeURI(shareUrl)}&text=${encodeURI(shareText)}&hashtags=animoto&related=animoto`,
      '_blank',
      `menubar=no, toolbar=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  }

  return (
    <Modal
      contentLabel="Share"
      isOpen={isOpen}
      onCloseModal={onShareModalClose}
      showCloseButton
      size={showSocialNetworks ? modalSizes.MEDIUM : modalSizes.SMALL}
    >
      <ModalHeader>{showSocialNetworks ? 'Share' : 'Share video link'}</ModalHeader>
      <div className={showSocialNetworks ? container : ''}>
        {showSocialNetworks && (
          <>
            <IconThumbnailButton
              icon={<BrandColorFacebookIcon />}
              onClick={onFacebookClick}
            >
              Facebook
            </IconThumbnailButton>
            <IconThumbnailButton
              icon={<BrandColorTwitterIcon />}
              onClick={onTwitterClick}
            >
              Twitter
            </IconThumbnailButton>
          </>
        )}
        <CopyLink
          pageName={pageName}
          resolution={resolution}
          showInput={!showSocialNetworks}
          url={shareUrl}
        />
      </div>
    </Modal>
  );
}

ShareModal.propTypes = {
  isOpen             : PropTypes.bool.isRequired,
  onCloseModal       : PropTypes.func.isRequired,
  producerName       : PropTypes.string,
  resolution         : PropTypes.string,
  shareUrl           : PropTypes.string.isRequired,
  showSocialNetworks : PropTypes.bool.isRequired,
  videoTitle         : PropTypes.string.isRequired
};

ShareModal.defaultProps = {
  producerName : '',
  resolution   : null
};
