/**
 * Checks to see if the execCommand('copy') is supported by the browser
 *
 * Not supported in Safari 9 and IE8, and earlier versions:
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
 *
 * @method isCopySupported
 *
 * @returns {Boolean} whether the browser supports the copy command
 */
export function isCopySupported() {
  return document.queryCommandSupported ? document.queryCommandSupported('copy') : false;
}

/**
 * Temporarily create a textarea element displayed outside of
 * the viewport, which is used to copy text to the clipboard
 *
 * @method copyToClipboard
 *
 * @param {String} text - the text to copy to the clpiboard
 *
 */
export function copyToClipboard(text) {
  if (!text || !isCopySupported()) {
    return;
  }

  const tempInput = document.createElement('textarea');
  tempInput.value = text;
  tempInput.setAttribute('readonly', '');
  tempInput.style.position = 'absolute';
  tempInput.style.left = '-9999px';
  document.body.appendChild(tempInput);

  tempInput.select();

  document.execCommand('copy');
  document.body.removeChild(tempInput);
}
