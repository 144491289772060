// extracted by mini-css-extract-plugin
var _1 = "BlogPosts-module_blog__h6TSb";
var _2 = "BlogPosts-module_blogImage__odXuV";
var _3 = "BlogPosts-module_blogTitle__r4JAk";
var _4 = "BlogPosts-module_blogWrapper__KUjua";
var _5 = "BlogPosts-module_blogsWrapper__Go3gu";
var _6 = "BlogPosts-module_header__I_taz";
var _7 = "BlogPosts-module_headerWrap__cmMg7";
var _8 = "BlogPosts-module_wave__Gj6gI";
var _9 = "BlogPosts-module_wrap__ZyVn5";
export { _1 as "blog", _2 as "blogImage", _3 as "blogTitle", _4 as "blogWrapper", _5 as "blogsWrapper", _6 as "header", _7 as "headerWrap", _8 as "wave", _9 as "wrap" }
export default { "blog": _1, "blogImage": _2, "blogTitle": _3, "blogWrapper": _4, "blogsWrapper": _5, "header": _6, "headerWrap": _7, "wave": _8, "wrap": _9 }
