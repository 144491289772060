import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { trackFeatureUsage } from '@animoto/utils/analytics';
// TODO REB-95 Clean up components in web2
import {
  Button as ButtonImport,
  BodySmall as BodySmallImport,
  HeaderExtraSmall as HeaderExtraSmallImport,
  TitleMedium as TitleMediumImport,
  TitleSmall as TitleSmallImport,
  HeaderSmall as HeaderSmallImport
} from '@animoto/components';
import evaluateFeature from '@animoto/ld-utils/evaluateFeature';

import {
  button,
  container,
  divider,
  header,
  desktop,
  number,
  steps,
  stepsContainer,
  title,
  number1,
  number2,
  number3
} from './VideoSteps.module.css';

const stepNumberCssClassMap = {
  number1,
  number2,
  number3
};

const videoStepsCopy = [
  {
    stepTitle : 'Add photos and video clips',
    body      : 'Select a template or start from scratch. Then drag and drop photos and video clips right into your project.'
  },
  {
    stepTitle : 'Personalize your video',
    body      : 'Quickly adjust colors, fonts, music, and more to customize your video. Match your own style or brand with ease.'
  },
  {
    stepTitle : 'Share and impress',
    body      : 'Produce and share your video in moments. Wow friends and family or boost business sales and engagement.'
  }
];

export default function VideoSteps({
  isMobile,
  onClick
}) {
  const isRebrand = evaluateFeature('rebrand', false);
  const Button = ButtonImport(isRebrand);
  const BodySmall = BodySmallImport(isRebrand);
  const HeaderExtraSmall = HeaderExtraSmallImport(isRebrand);
  const TitleMedium = TitleMediumImport(isRebrand);
  const TitleSmall = TitleSmallImport(isRebrand);
  const HeaderSmall = HeaderSmallImport(isRebrand);

  const trackClick = () => {
    trackFeatureUsage({
      scope   : 'visitor-play-page',
      feature : 'video-in-steps',
      action  : 'click'
    });
  };
  const onClickButton = () => {
    trackClick();
    onClick();
  };

  const getStepNumberClasses = (index) => classNames(
    stepNumberCssClassMap[`number${index + 1}`],
    number
  );

  const getDesktopBody = () => (
    <>
      <TitleMedium className={title}>Make a video in 3 easy steps</TitleMedium>
      <div className={stepsContainer}>
        { videoStepsCopy.map(({ stepTitle, body }, i) => (
          <div key={stepTitle} className={steps}>
            <div className={getStepNumberClasses(i)} />
            <HeaderSmall className={header}>{stepTitle}</HeaderSmall>
            <BodySmall>{body}</BodySmall>
          </div>
        ))}
      </div>
    </>
  );
  const getMobileBody = () => (
    <>
      <TitleSmall className={title}>Make a video in 3 easy steps</TitleSmall>
      <div className={stepsContainer}>
        { videoStepsCopy.map(({ stepTitle }, i) => (
          <div key={stepTitle} className={steps}>
            <div className={getStepNumberClasses(i)} />
            <HeaderExtraSmall className={header}>{stepTitle}</HeaderExtraSmall>
          </div>
        ))}
      </div>
    </>
  );

  const containerClasses = classNames(container, { [desktop] : !isMobile });

  return (
    <>
      <div className={divider} />
      <div className={containerClasses}>
        {!isMobile ? (
          getDesktopBody()
        ) : (
          getMobileBody()
        )}
        <Button
          className={button}
          onClick={onClickButton}
        >
          Make a video
        </Button>
      </div>
    </>
  );
}

VideoSteps.propTypes = {
  isMobile : PropTypes.bool.isRequired,
  onClick  : PropTypes.func.isRequired
};
