import isObject from 'lodash-es/isObject';

const methods = {
  create : 'POST',
  update : 'PUT',
  patch  : 'PATCH',
  delete : 'DELETE',
  read   : 'GET'
};
const headers = window.AS_HEADERS || {
  'Content-Type' : 'application/vnd.animoto-v4+json',
  Accept         : 'application/vnd.animoto-v4+json'
};

let token;

function setHeaders(header) {
  const defaults = headers;
  const extended = {
    'X-Animoto-Authorization' : `Bearer ${token}`, ...defaults, ...header
  };

  return new Headers({ 'X-Animoto-Accept' : extended.Accept, ...extended });
}

function validateMethod(method) {
  const m = methods[method];

  if (typeof m === 'undefined') {
    if (typeof debug !== 'undefined') {
      debug.log(`Invalid method passed to api: ${method}`);
    }
    return false;
  }

  return m;
}

async function makeApiCall(path, method, options) {
  const params = {
    method,
    headers : setHeaders(options.header),
    ...options
  };

  if (isObject(params.body) && (method === 'POST' || method === 'PUT' || method === 'PATCH')) {
    params.body = JSON.stringify(params.body);
  }

  if (!params.contentType && (params.type === 'PUT' || params.type === 'POST' || method === 'PATCH')) {
    params.contentType = 'application/vnd.animoto-v2+json';
  }

  return fetch(path, params);
}

export function getToken() {
  if (window.user_token) {
    return window.user_token;
  }

  if (window.client_token) {
    return window.client_token;
  }

  return null;
}

export function setToken(accessToken) {
  if (accessToken) {
    token = accessToken;
    return;
  }

  token = getToken();
}

export async function api(...args) {
  const path = args.shift();

  let next = args.shift();
  let method;
  let params;
  let callback;
  let type;

  if (!getToken()) {
    window.location.href = `/log_in?return_to=${encodeURIComponent(window.location.href)}`;
    return new $.Deferred();
  }

  // Loop through arugments and set method, callback and params accordingly
  while (next) {
    type = typeof next;
    if (type === 'string' && !method) {
      // Validate the method
      method = validateMethod(next.toLowerCase());
    } else if (type === 'object' && !params) {
      params = next;
    } else {
      if (typeof debug !== 'undefined') {
        debug.log(`Invalid argument passed to api: ${next}`);
      }
      return false;
    }
    next = args.shift();
  }
  params = params || {};

  // Get by default
  if (!method) {
    method = methods.read;
  }

  //  Make the request
  return makeApiCall(path, method, params, callback);
}
