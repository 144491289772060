/**
 * Opens facebook's native dialog to share link
 *
 * @param {String} url - link to share
 *
 * @method facebookTimelineShare
 *
 */
export default function facebookTimelineShare(url) {
  animoto.fb.share.timelineShare(url);
}
