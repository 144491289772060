/**
 * Redirects to a url
 *
 * @method redirect
 * @public
 *
 * @param {String} url - Url to redirect to
 * @param {Object} [_w] - Window object for testing
 *
 * @example
 *   redirect('/someurl');
 */
export default function redirect(url, _w) {
  /* istanbul ignore next */
  const w = _w || window;

  if (!url) {
    return;
  }

  w.location.href = url;
}
