// TODO REB-95 Clean up components in web2
import {
  Button as ButtonImport,
  Input as InputImport,
  Tooltip as TooltipImport,
  tooltipPlacements,
  CopyLinkIcon as CopyLinkIconImport,
  IconThumbnailButton as IconThumbnailButtonImport
} from '@animoto/components';
import React, { useEffect, useState } from 'react';
import noop from 'lodash-es/noop';
import { trackEvent } from '@animoto/utils/analytics';
import PropTypes from 'prop-types';
import evaluateFeature from '@animoto/ld-utils/evaluateFeature';
import { copyToClipboard } from '../../../../utils/copyToClipboard';
import trackShare from '../../../../utils/trackShare';

import { copyButton } from './CopyLink.module.css';

export default function CopyLink({
  pageName,
  resolution,
  showInput,
  url
}) {
  const isRebrand = evaluateFeature('rebrand', false);
  const Button = ButtonImport(isRebrand);
  const CopyLinkIcon = CopyLinkIconImport(isRebrand);
  const IconThumbnailButton = IconThumbnailButtonImport(isRebrand);
  const Input = InputImport(isRebrand);
  const Tooltip = TooltipImport(isRebrand);

  const [tooltipTimeoutId, setTooltipTimeoutId] = useState(null);

  function onCopyLink() {
    trackShare('share', 'link', resolution, `link-button_clicked:${pageName}`);

    copyToClipboard(url);

    if (tooltipTimeoutId) {
      clearTimeout(tooltipTimeoutId);
    }

    const timeoutId = setTimeout(() => {
      setTooltipTimeoutId(null);
    }, 1000);
    setTooltipTimeoutId(timeoutId);
  }

  useEffect(() => {
    if (showInput) {
      trackEvent(pageName, 'dialog-LinkView');
    }
  }, [pageName, showInput]);

  useEffect(() => () => {
    if (tooltipTimeoutId) {
      clearTimeout(tooltipTimeoutId);
    }
  }, [tooltipTimeoutId]);

  return (
    <>
      {showInput && (
        <Input label="" name="copy" onChange={noop} value={url} />
      )}
      <Tooltip isVisible={!!tooltipTimeoutId} overlayText="Link copied!" placement={tooltipPlacements.TOP}>
        {!showInput ? (
          <IconThumbnailButton
            icon={<CopyLinkIcon />}
            onClick={onCopyLink}
          >
            Copy Link
          </IconThumbnailButton>
        ) : (
          <Button className={copyButton} icon={<CopyLinkIcon />} onClick={onCopyLink}>Copy Link</Button>
        )}
      </Tooltip>
    </>
  );
}

CopyLink.propTypes = {
  pageName   : PropTypes.string.isRequired,
  resolution : PropTypes.string.isRequired,
  showInput  : PropTypes.bool.isRequired,
  url        : PropTypes.string.isRequired
};
