import { reportMessage } from '@animoto/utils/sentry';

/**
 * Computes playable resolutions available to be
 * played in format expected by JW Player
 *
 * @method getVideoSources
 *
 * @param   {Array} videoFormats - video formats
 * @param   {String=} defaultResolution - resolution to use by default
 *
 * @returns {Array} Resolutions available to be played
 */
export default function(videoFormats, defaultResolution = '') {
  const finalizedSources = videoFormats.filter((format) => format.purpose !== 'preview');

  let highestResolutionIndex = 0;
  let highestResolution = 0;
  let sources = finalizedSources.length ? finalizedSources : videoFormats;

  // Adding logging to debug https://sentry.io/organizations/animoto/issues/2168191733/events/9b2dd1bb0d384d7c8bc0fbfe1f1ae819/?project=5196378&query=is%3Aunresolved
  sources.forEach((source) => {
    if (!source.links) {
      reportMessage('Source does not have links', {
        level : 'error',
        extra : { sources }
      });
    }
  });
  sources = sources.filter((source) => source.links !== undefined);

  sources = sources.map((format, index) => {
    const { resolution } = format;
    const resolutionNumber = parseInt(resolution, 10);
    const { file, video } = format.links;

    if (resolutionNumber > highestResolution) {
      highestResolution = resolutionNumber;
      highestResolutionIndex = index;
    }

    const sourcesSource = {
      src   : file || video,
      label : resolution
    };

    if (defaultResolution === resolution) {
      sourcesSource.selected = true;
    }

    return sourcesSource;
  });

  // If no default resolution passed as argument set highest resolution as default
  if (!defaultResolution) {
    const highestResolutionSource = sources[highestResolutionIndex];
    if (highestResolutionSource) {
      highestResolutionSource.selected = true;
    }
  }

  return sources;
}
